import styled from 'styled-components';

import Layout from '../components/Layout/Layout';
import SocialNetworks from '../components/Navigation/SocialNetworks';
import AppIcons from '../components/AppIcons/AppIcons';
import DevSkillsList from '../components/CV/DevSkillsList';
import DownloadCV from '../components/CV/DownloadCV';

import {
	FRONT_AND_BACK,
	CMS,
	API_GIT,
	TECH_TOOLS,
	OTHER,
} from '../components/AppIcons/AppsList';
import myPhoto from '../assets/images/photo3.jpg';

const Photo = styled.img`
	height: 225px;
	border-radius: 50%;
	margin: 4rem 0;
`;
const MainTitleWrapper = styled.div`
	h1 {
		display: inline-block;
	}
`;
const AccentColor = styled.h1`
	display: inline-block;
	color: var(--accent-color);
`;
const Description = styled.p`
	max-width: 800px;
	margin-bottom: 2rem;
`;

const Home = props => {
	return (
		<Layout>
			<section id='home'>
				<Photo src={myPhoto} alt='My photo' />
				<MainTitleWrapper>
					<AccentColor>Nastia</AccentColor> <h1>Zhyrnova</h1>
				</MainTitleWrapper>
				<h4>Frontend Developer</h4>
				<Description>
					Problem solver | Perfectionist | Adaptive and communicative |
					Pixel-perfect design | Fast learner | Nice person :)
				</Description>
				<SocialNetworks />
			</section>
			<section id='skills'>
				<h3>Frontend & backend tech stack</h3>
				<AppIcons list={FRONT_AND_BACK} size='3.5rem' />
				<h3>APIs and version control</h3>
				<AppIcons list={API_GIT} size='3.5rem' />
				<h3>Content management</h3>
				<AppIcons list={CMS} size='3.5rem' />
				<h3>Software design and principles</h3>
				<DevSkillsList />
				<h3>Tools</h3>
				<AppIcons list={TECH_TOOLS} size='3.5rem' />
				<h3>Other software</h3>
				<AppIcons list={OTHER} size='2.75rem' />
			</section>
			{/* <section id='cv'>
				<h3>My CV:</h3>
				<DownloadCV>Download CV in PDF</DownloadCV>
			</section> */}
		</Layout>
	);
};

export default Home;
