import {
	SiReact,
	SiRedux,
	SiJavascript,
	SiTypescript,
	SiHtml5,
	SiCss3,
	SiPhp,
	SiFirebase,
	SiPostman,
	SiVisualstudiocode,
	SiNpm,
	SiStyledcomponents,
	SiReactrouter,
	SiAdobephotoshop,
	SiAdobeillustrator,
	SiAdobexd,
	SiAdobeindesign,
	SiSap,
	SiTrello,
	SiNotion,
	SiMicrosoftexcel,
	SiJira,
	SiNodedotjs,
	SiIntellijidea,
	SiStoryblok,
	SiGitlab,
	SiJquery,
	SiAstro,
	SiGithub,
	SiBitbucket,
} from 'react-icons/si';
import { AiTwotoneApi } from 'react-icons/ai';
import { IoLogoSass } from 'react-icons/io';
import { FaGitAlt } from 'react-icons/fa';
import { ImWordpress } from 'react-icons/im';
import { TbBrandNextjs } from 'react-icons/tb';

export const FRONT_AND_BACK = [
	{
		title: 'JavaScript',
		subtitle: 'ES6',
		// progress: 95,
		icon: <SiJavascript />,
	},
	{
		title: 'ReactJS',
		subtitle: '(Classes, Hooks)',
		// progress: 90,
		icon: <SiReact />,
	},
	{
		title: 'Redux & toolkit,',
		subtitle: 'Redux-thunk',
		// progress: 80,
		icon: <SiRedux />,
	},
	{
		title: 'React Router',
		// progress: 80,
		icon: <SiReactrouter />,
	},
	{
		title: 'styled-components',
		icon: <SiStyledcomponents />,
	},
	{
		title: 'NextJS',
		icon: <TbBrandNextjs />,
	},
	{
		title: 'Astro',
		icon: <SiAstro />,
	},
	{
		title: 'TypeScript',
		icon: <SiTypescript />,
	},
	{
		title: 'HTML',
		icon: <SiHtml5 />,
	},
	{
		title: 'CSS',
		icon: <SiCss3 />,
	},
	{
		title: 'SASS',
		icon: <IoLogoSass />,
	},
	{
		title: 'jQuery',
		icon: <SiJquery />,
	},
	{
		title: 'NodeJS',
		icon: <SiNodedotjs />,
		// progress: 35,
	},

	{
		title: 'PHP',
		icon: <SiPhp />,
		// progress: 35,
	},
];

export const API_GIT = [
	{
		title: 'RESTful API',
		icon: <AiTwotoneApi />,
	},
	{
		title: 'Git',
		icon: <FaGitAlt />,
	},
	{
		title: 'GitHub',
		icon: <SiGithub />,
	},
	{
		title: 'GitLab',
		icon: <SiGitlab />,
	},
	{
		title: 'JIRA',
		icon: <SiJira />,
	},
	{
		title: 'Bitbucket',
		icon: <SiBitbucket />,
	},
];

export const CMS = [
	{
		title: 'Storyblok',
		icon: <SiStoryblok />,
	},
	{
		title: 'Firebase',
		icon: <SiFirebase />,
	},
	{
		title: 'Wordpress',
		icon: <ImWordpress />,
	},
];

export const TECH_STACK = [
	{
		title: 'JavaScript',
		subtitle: 'ES6',
		// progress: 95,
		icon: <SiJavascript />,
	},
	{
		title: 'ReactJS',
		subtitle: '(Classes, Hooks)',
		// progress: 90,
		icon: <SiReact />,
	},
	{
		title: 'Redux & toolkit,',
		subtitle: 'Redux-thunk',
		// progress: 80,
		icon: <SiRedux />,
	},
	{
		title: 'React Router',
		// progress: 80,
		icon: <SiReactrouter />,
	},
	{
		title: 'styled-components',
		icon: <SiStyledcomponents />,
	},
	{
		title: 'NextJS',
		icon: <TbBrandNextjs />,
	},
	{
		title: 'Astro',
		icon: <SiAstro />,
	},
	{
		title: 'TypeScript',
		icon: <SiTypescript />,
	},
	{
		title: 'HTML',
		icon: <SiHtml5 />,
	},
	{
		title: 'CSS',
		icon: <SiCss3 />,
	},
	{
		title: 'SASS',
		icon: <IoLogoSass />,
	},
	{
		title: 'NodeJS',
		icon: <SiNodedotjs />,
	},
	{
		title: 'jQuery',
		icon: <SiJquery />,
	},
	{
		title: 'Wordpress',
		icon: <ImWordpress />,
	},
	{
		title: 'PHP',
		icon: <SiPhp />,
	},
];

export const TECH_TOOLS = [
	{
		title: 'npm',
		icon: <SiNpm />,
	},
	{
		title: 'Postman',
		icon: <SiPostman />,
	},
	{
		title: 'VS Code',
		icon: <SiVisualstudiocode />,
	},
	{
		title: 'IntelliJ IDEA',
		icon: <SiIntellijidea />,
	},
];

export const AGILE = [
	{
		title: 'Trello',
		icon: <SiTrello />,
	},
	{
		title: 'Notion',
		icon: <SiNotion />,
	},
];

export const OTHER = [
	{
		title: 'Adobe',
		subtitle: 'XD',
		icon: <SiAdobexd />,
	},
	{
		title: 'Adobe',
		subtitle: 'Photoshop',
		icon: <SiAdobephotoshop />,
	},
	{
		title: 'Adobe',
		subtitle: 'Illustrator',
		icon: <SiAdobeillustrator />,
	},
	{
		title: 'Adobe InDesign',
		icon: <SiAdobeindesign />,
	},
	{
		title: 'SAP',
		subtitle: '(FI, MM)',
		icon: <SiSap />,
	},
];
