import { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled, { useTheme } from 'styled-components';

import Layout from '../components/Layout/Layout';
import Button from '../components/UI/Button';
import SocialNetworks from '../components/Navigation/SocialNetworks';
import AppIcons from '../components/AppIcons/AppIcons';
import DevSkillsList from '../components/CV/DevSkillsList';
import DownloadCV from '../components/CV/DownloadCV';

import myPhoto from '../assets/images/photo2.jpg';
import {
	FRONT_AND_BACK,
	CMS,
	API_GIT,
	TECH_TOOLS,
	OTHER,
} from '../components/AppIcons/AppsList';

const Hero = styled.div`
	padding-top: 2rem;
	max-width: 800px;
	display: flex;
	align-items: center;
	margin: 0 auto;
	gap: 2rem;
	margin-bottom: 2rem;
	text-align: left;
	img {
		height: 250px;
		border-radius: 50%;
		margin: 0 auto;
		padding-bottom: 1rem;
	}
	div {
		h3 {
			padding: 1rem 0;
			margin: 0 auto;
		}
		p {
			padding-bottom: 1rem;
			text-align: justify;
		}
	}
	@media (max-width: 767px) {
		flex-direction: column;
		text-align: center;
		margin-bottom: 2rem;
	}
`;

const Skills = styled.section`
	padding-bottom: 4rem;
	h3 {
		padding-top: 4rem;
	}
	button {
		margin: 4rem 0 0;
	}
`;

const GoodAtList = styled.ul`
	max-width: 800px;
	margin: 0 auto;
	list-style-type: '→  ';
	color: var(--accent-color);
	h3 {
		padding-top: 0;
		color: ${props => props.theme.mainText};
	}
	li {
		h5,
		p {
			color: ${props => props.theme.mainText};
		}
		margin-bottom: 1.5rem;
		text-align: justify;
	}
	@media (max-width: 767px) {
		list-style: none;
	}
`;

const CTA = styled.p`
	max-width: 500px;
	margin: 0 auto;
	font-size: 1.75rem;
	font-weight: 600;
	color: var(--accent-color);
	padding-top: 4rem;
`;

const About = () => {
	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);

	const history = useHistory();
	const theme = useTheme();

	return (
		<Layout>
			<section>
				<Hero>
					<div>
						<img src={myPhoto} alt='Photo of me' />
						<SocialNetworks />
					</div>
					<div>
						<h3>Hi! I am Nastia and I am looking for ReactJS developer job</h3>
						<p>
							I am a self-taught frontend developer who is looking for a company
							where I could apply my knowledge and experience and keep improving
							in ReactJS.
						</p>
						<p>
							Since October 2021 I've been working as a full-time front-end
							JavaScript developer at a German digital agency.
						</p>
						<p>
							I've worked in business, marketing and finances before, but COVID
							times gave me a chance to realize what I am really passionate
							about.
						</p>
						<p>
							Now I am ready to move on to the next stage of my dev journey... (
							<em>Remote or hybrid in Málaga area ;)</em> )
						</p>
					</div>
				</Hero>
				<DownloadCV>Download detailed CV</DownloadCV>
			</section>
			<Skills>
				<h3>Frontend & backend tech stack</h3>
				<AppIcons list={FRONT_AND_BACK} size='3.5rem' />
				<h3>APIs and version control</h3>
				<AppIcons list={API_GIT} size='3.5rem' />
				<h3>Content management</h3>
				<AppIcons list={CMS} size='3.5rem' />
				<h3>Software design and principles</h3>
				<DevSkillsList />
				<h3>Tools</h3>
				<AppIcons list={TECH_TOOLS} size='2.75rem' />
			</Skills>
			<GoodAtList>
				<h3>Besides that, what I am good at:</h3>
				<li>
					<h5>Learning: </h5>
					<p>
						I love acquiring new skills and improve them, whether it is a new
						language, new technology or gardening.
					</p>
				</li>
				<li>
					<h5>Finding solutions: </h5>
					<p>
						Give me a problem and it will be a fuel which I run on for days and
						weeks. I love challenges and see it as a trampoline to gain new
						skills and learn how to improve the current way of things. I do a
						lot of research, and with trial and error find a way to solve it.
					</p>
				</li>
				<li>
					<h5>Automating processes: </h5>
					<p>
						As much as I am a hard working person, I agree to the phrase
						“Laziness is an engine of progress”. I resent repetitive tasks. Once
						I detect one, gears start spinning in my head on the double speed
						until I find a way to avoid it. I apply it in all of my jobs, for
						instance, I got an award while working in “Xerox” for creating
						macros which saved 75% of time in everyday reports generation.
					</p>
				</li>
				<li>
					<h5>Perfectionism and structured approach: </h5>
					<p>
						For me everything should be done well until the last tiny bit.
						Otherwise I wouldn’t be any good at my previous accounting & finance
						positions ;) I apply it also to learning: if I start gaining a new
						skill I do it structurally from basics to more advanced levels, so I
						won’t miss any detail or fundamental knowledge.
					</p>
				</li>
				<li>
					<h5>Visuals: </h5>
					<p>
						I have experience in graphic design, painting and illustration,
						which come in handy also when working in front-end: I can detect
						wrong font-weight or padding in one sight, see incorrect colours and
						very well understand what a right look & feel should look like.
					</p>
				</li>
				<li>
					<h5>Adapting: </h5>
					<p>
						Consider me as a non-conflict person who adapts very good to any
						environment. I used to live in 3 foreign countries and, as much as
						they are all distinct, I didn’t mind their specialities and learned
						quite fast to deal with them. The same applies to friendships and
						working environments.
					</p>
				</li>
				<li>
					<h5>Communicating: </h5>
					<p>
						Despite the fact I am an introvert, I enjoy being in contact with my
						teammates: discuss new ideas, find solutions and express my
						opinions. I might not do it right away, but once I gain confidence
						with people, I consider myself quite talkative and very opened
						person. Also I know what and when I should or shouldn’t say when it
						comes to any communitaction: inside of company or with external
						clients.
					</p>
				</li>
				<li>
					<h5>Doing anything: </h5>
					<p>
						Seriously. I think you should do your best in any activity. I am
						really versatile and good at doing a great deal of things: work-wise
						or in my hobbies. At my previous job I did customer attention and
						project management, web development, accounting, graphic design,
						marketing strategies, internal organisation… And what I am not good
						at, I keep improving. Just don’t make my sing, ever. I really can’t
						and it’s not fixable.
					</p>
				</li>
			</GoodAtList>
			<CTA>Are my skills is what your company may be interested in?</CTA>
			<Button onClick={() => history.push('/contact')}>Contact me!</Button>
		</Layout>
	);
};

export default About;
