import styled from 'styled-components';

import AppIcon from './AppIcon';

const IconsContainer = styled.div`
	display: grid;
	gap: 2.5rem 1rem;
	grid-template-columns: repeat(
		${props => (props.columns < 7 ? props.columns : 7)},
		${props => (props.columns < 7 ? '96px' : '1fr')}
	);
	align-items: start;
	justify-content: center;
	max-width: 800px;
	margin: 0 auto;

	@media (max-width: 767px) {
		grid-template-columns: repeat(4, 96px);
	}

	@media (max-width: 491px) {
		grid-template-columns: repeat(3, 96px);
	}
`;

const AppIcons = props => {
	const icons = props.list.map(icon => (
		<AppIcon
			icon={icon.icon}
			title={icon.title}
			subtitle={icon.subtitle}
			key={`${icon.title} ${icon.subtitle && icon.subtitle}`}
			size={props.size}
			progress={icon.progress}
		/>
	));

	return <IconsContainer columns={props.list.length}>{icons}</IconsContainer>;
};

export default AppIcons;
