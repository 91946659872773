import styled, { useTheme } from 'styled-components';

import Icon from '../UI/Icon';

const IconWrapper = styled.div`
	display: grid;
	grid-template-rows: 4;
	justify-items: center;
	align-content: space-between;
	gap: 0.5rem;
	height: 100%;

	p {
		font-size: 0.75rem;
		color: ${props => props.theme.secondaryText};
	}

	.progressbar_outer {
		height: 6px;
		width: 65%;
		border-radius: 6px;
		background-color: ${props => props.theme.additionalText};
		opacity: 0.75;

		.progressbar_inner {
			height: 100%;
			text-align: left;
			background-color: var(--accent-color);
			border-radius: 6px 0 0 6px;
		}
	}
`;

const AppIcon = props => {
	const theme = useTheme();
	const progress = `${props.progress}%`;

	return (
		<IconWrapper
			title={`${props.title} ${props.subtitle ? props.subtitle : ''}`}>
			<Icon
				icon={props.icon}
				title={`${props.title} ${props.subtitle ? props.subtitle : ''}`}
				size={props.size}
				color={theme.secondaryText}
			/>
			<div>
				<p>{props.title}</p>
				{props.subtitle && <p>{props.subtitle}</p>}
			</div>
			{props.progress && (
				<div className='progressbar_outer'>
					<div className='progressbar_inner' style={{ width: progress }}></div>
				</div>
			)}
		</IconWrapper>
	);
};

export default AppIcon;
